import {alfServiceTypes, efServiceAssignmentTypes, efServiceTypes} from '@ace-de/eua-entity-types';
import {svgIcons} from '@ace-de/ui-components';

export const serviceIcons = {
    [alfServiceTypes.VEHICLE_OPENING]: {icon: svgIcons.vehicleOpeningIcon},
    [alfServiceTypes.RECOVERY]: {icon: svgIcons.recoveryIcon},
    [alfServiceTypes.ROADSIDE_ASSISTANCE]: {icon: svgIcons.breakdownIcon},
    [alfServiceTypes.TOWING]: {icon: svgIcons.towingIcon},
    [alfServiceTypes.PICKUP]: {icon: svgIcons.shipmentIcon},
    [alfServiceTypes.RENTAL_CAR]: {icon: svgIcons.carRentalIcon},
    [alfServiceTypes.RENTAL_CAR_DELIVERY]: {icon: svgIcons.carRentalIcon},
    [alfServiceTypes.RENTAL_CAR_EMERGENCY_SERVICE]: {icon: svgIcons.carRentalIcon},
    [alfServiceTypes.ACCOMMODATION]: {icon: svgIcons.overnightStayIcon},
    [alfServiceTypes.SHORT_TRIP]: {icon: svgIcons.taxiIcon},
    [alfServiceTypes.RETURN_JOURNEY]: {icon: svgIcons.flightIcon},
    [alfServiceTypes.SUBSTITUTE_DRIVER]: {icon: svgIcons.personOffIcon},
    [alfServiceTypes.MEDICAL_CLEARANCE]: {icon: svgIcons.hospitalIcon},
    [alfServiceTypes.SICK_PERSON]: {icon: svgIcons.hospitalIcon},
    [alfServiceTypes.VEHICLE_RETURN]: {icon: svgIcons.pickupIcon},
    [alfServiceTypes.TRAILER_RETURN]: {icon: svgIcons.trailerIcon},
    [alfServiceTypes.DEATH]: {icon: svgIcons.deathCaseIcon},
    [alfServiceTypes.MEDICINE_SHIPMENT]: {icon: svgIcons.medicationIcon},
    [alfServiceTypes.MEDICAL_ADVICE]: {icon: svgIcons.healingIcon},
    [alfServiceTypes.LEGAL_ADVICE]: {icon: svgIcons.legalIcon},
    [alfServiceTypes.EXTENDED_ROADSIDE_ASSISTANCE]: {icon: svgIcons.breakdownExtendedIcon},
    [alfServiceTypes.SPARE_PARTS_SERVICE]: {icon: svgIcons.sparePartsServiceIcon},
    [alfServiceTypes.VEHICLE_STORAGE]: {icon: svgIcons.carportInsideIcon},
    [alfServiceTypes.LUGGAGE_SERVICE]: {icon: svgIcons.luggageServiceIcon},
    [alfServiceTypes.CLEARANCE_SCRAPPING]: {icon: svgIcons.scrapingIcon},
    [alfServiceTypes.SICK_PERSON_TRANSPORT]: {icon: svgIcons.ambulanceIcon},
    [alfServiceTypes.RETURN_SERVICE_AFTER_HOSPITAL]: {icon: svgIcons.flightIcon},
    [alfServiceTypes.RETURN_SERVICE_FOR_KIDS]: {icon: svgIcons.flightIcon},
    [alfServiceTypes.RETURN_SERVICE_FOR_PETS]: {icon: svgIcons.flightIcon},
    [alfServiceTypes.RESCUE_AND_RECOVERY]: {icon: svgIcons.hospitalIcon},
    [alfServiceTypes.SICK_VISIT]: {icon: svgIcons.hospitalIcon},
    [alfServiceTypes.NOTIFICATION_SERVICE]: {icon: svgIcons.notificationsIcon},
    [alfServiceTypes.DOCUMENT_SERVICE]: {icon: svgIcons.memberIcon},
    [alfServiceTypes.PAYMENT_METHOD_LOSS]: {icon: svgIcons.paymentLossIcon},
    [alfServiceTypes.TRAVEL_CANCELLATION]: {icon: svgIcons.travelCancellationIcon},
    [alfServiceTypes.CREDIT_SERVICE_FOR_PROSECUTION]: {icon: svgIcons.creditIcon},
    [alfServiceTypes.HELP_IN_CASE_OF_EMERGENCY]: {icon: svgIcons.warningAlertIcon},
    [alfServiceTypes.OTHER_SERVICES]: {icon: svgIcons.otherIcon},
    [alfServiceTypes.EMPTY_TRIP]: {icon: svgIcons.noServiceIcon},
    [alfServiceTypes.HOTEL_0]: {icon: svgIcons.overnightStayIcon},
    [alfServiceTypes.HOTEL_7]: {icon: svgIcons.overnightStayIcon},
    [alfServiceTypes.HOTEL_19]: {icon: svgIcons.overnightStayIcon},
    [alfServiceTypes.ROADKILL]: {icon: svgIcons.deerIcon},
    [alfServiceTypes.MARTEN_BITE]: {icon: svgIcons.deerIcon},
    [alfServiceTypes.ELEMENTAL]: {icon: svgIcons.deerIcon},
    [alfServiceTypes.ACCIDENT_AID]: {icon: svgIcons.accidentIcon},
    [alfServiceTypes.ROADSIDE_ASSISTANCE_WORLDWIDE]: {icon: svgIcons.breakdownIcon},
    [alfServiceTypes.DOOR_LOCK_SERVICE]: {icon: svgIcons.lockedIcon},
    [alfServiceTypes.IMPORTANT_OBJECTS]: {icon: svgIcons.warningOutlinedIcon},
    [alfServiceTypes.DAILY_HOSPITAL_ALLOWANCE]: {icon: svgIcons.hospitalIcon},
    [alfServiceTypes.DEATH_BENEFIT]: {icon: svgIcons.deathCaseIcon},
    [alfServiceTypes.FUEL_SUBSIDY]: {icon: svgIcons.fuelGrantIcon},
    [alfServiceTypes.INFLATION_SUBSIDY]: {icon: svgIcons.inflationIcon},
    [alfServiceTypes.TONNAGE_SURCHARGE]: {icon: svgIcons.tonnageIcon},
    [alfServiceTypes.ADDITIONAL_STAFF]: {icon: svgIcons.additionalStaffIcon},
    [alfServiceTypes.REPAIR_DIAGNOSTICS_COSTS]: {icon: svgIcons.euroSymbolIcon},
    [alfServiceTypes.TOLL_FEES]: {icon: svgIcons.tollFeesIcon},
    [alfServiceTypes.FERRY]: {icon: svgIcons.ferryIcon},
    [alfServiceTypes.ASSISTANCE_FEES]: {icon: svgIcons.assistanceFeeIcon},
    [alfServiceTypes.TAXI_0]: {icon: svgIcons.taxiIcon},
    [alfServiceTypes.TAXI_7]: {icon: svgIcons.taxi7Icon},
    [alfServiceTypes.TAXI_19]: {icon: svgIcons.taxi19Icon},
    [alfServiceTypes.GLASS_DAMAGE]: {icon: svgIcons.glassDamageIcon},
    [alfServiceTypes.CAMPING_CARD]: {icon: svgIcons.documentIcon},
    [alfServiceTypes.ROAD_SAFETY_TRAINING]: {icon: svgIcons.roadSafetyIcon},
    [alfServiceTypes.ESTIMATION_EU]: {icon: svgIcons.estimationIcon},
    [alfServiceTypes.ESTIMATION_NON_EU]: {icon: svgIcons.estimationIcon},
    [alfServiceTypes.RETURN_IN_CASE_OF_DEATH]: {icon: svgIcons.deathCaseIcon},
    [efServiceAssignmentTypes.VEHICLE]: {icon: svgIcons.vehicleIcon},
    [efServiceAssignmentTypes.SICK_PERSON]: {icon: svgIcons.hospitalIcon},
    [alfServiceTypes.CUSTOMS]: {icon: svgIcons.customsIcon},
    [efServiceTypes.NO_SERVICE]: {icon: svgIcons.noServiceIcon},
    [alfServiceTypes.SURCHARGE_TRAILER]: {icon: svgIcons.trailerIcon},
};
