import React, {useState, useEffect, useRef, useCallback, Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import debounce from 'lodash.debounce';
import {isValidIBAN, electronicFormatIBAN} from 'ibantools';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {europeanCountries, alfContactTypes, alfAccountTypes, alfCreditorTemplateCodeTypes, alfDebtorTemplateCodeTypes, alfClientTypes} from '@ace-de/eua-entity-types';
import {useStyles, Form, SelectField, InputField, ButtonPrimary, ButtonSecondary, Divider, Option, AutocompleteField, AutosuggestField, locationIcon} from '@ace-de/ui-components';
import {Icon, saveIcon, arrowDownIcon, communicationIcon} from '@ace-de/ui-components/icons';
import * as contactSelectors from '../contactSelectors';
import * as contactActionTypes from '../contactActionTypes';
import config from '../../config';

const contactTypeToCreditorTemplateCodeMap = {
    [alfClientTypes.ACE]: {
        [alfContactTypes.COMPANY]: [
            alfCreditorTemplateCodeTypes.VENDOR_EU_COMPANY,
            alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_COMPANY,
            alfCreditorTemplateCodeTypes.VENDOR_NON_EU_COMPANY,
        ],
        [alfContactTypes.PERSON]: [
            alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_PERSON,
            alfCreditorTemplateCodeTypes.VENDOR_EU_PERSON,
            alfCreditorTemplateCodeTypes.VENDOR_NON_EU_PERSON,
        ],
    },
    [alfClientTypes.WACE]: {
        [alfContactTypes.COMPANY]: [
            alfCreditorTemplateCodeTypes.VENDOR_EU_COMPANY_WACE,
            alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_COMPANY_WACE,
            alfCreditorTemplateCodeTypes.VENDOR_NON_EU_COMPANY_WACE,
        ],
        [alfContactTypes.PERSON]: [
            alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_PERSON_WACE,
            alfCreditorTemplateCodeTypes.VENDOR_EU_PERSON_WACE,
            alfCreditorTemplateCodeTypes.VENDOR_NON_EU_PERSON_WACE,
        ],
    },
};

const contactTypeToDebtorTemplateCodeMap = {
    [alfClientTypes.ACE]: {
        [alfContactTypes.COMPANY]: [
            alfDebtorTemplateCodeTypes.CUSTOMER_DOMESTIC_COMPANY,
            alfDebtorTemplateCodeTypes.CUSTOMER_EU_COMPANY,
            alfDebtorTemplateCodeTypes.CUSTOMER_NON_EU_COMPANY,
        ],
        [alfContactTypes.PERSON]: [
            alfDebtorTemplateCodeTypes.CUSTOMER_DOMESTIC_PERSON,
            alfDebtorTemplateCodeTypes.CUSTOMER_EU_PERSON,
            alfDebtorTemplateCodeTypes.CUSTOMER_NON_EU_PERSON,
        ],
    },
    [alfClientTypes.WACE]: {
        [alfContactTypes.PERSON]: [],
        [alfContactTypes.COMPANY]: [
            alfDebtorTemplateCodeTypes.CUSTOMER_EU_COMMISSIONER_WACE,
            alfDebtorTemplateCodeTypes.CUSTOMER_NON_EU_COMMISSIONER_WACE,
            alfDebtorTemplateCodeTypes.CUSTOMER_DOMESTIC_WACE,
        ],
    },
};

const vatNumberDependencies = {
    [alfAccountTypes.CREDITOR]: [
        alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_COMPANY,
        alfCreditorTemplateCodeTypes.VENDOR_EU_COMPANY,
        alfCreditorTemplateCodeTypes.VENDOR_NON_EU_COMPANY,
        alfCreditorTemplateCodeTypes.VENDOR_DOMESTIC_COMPANY_WACE,
        alfCreditorTemplateCodeTypes.VENDOR_EU_COMPANY_WACE,
        alfCreditorTemplateCodeTypes.VENDOR_NON_EU_COMPANY_WACE,
    ],
    [alfAccountTypes.DEBTOR]: [],
};

const swiftCodeRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
const vatNumberRegex = /^(DE)?[0-9]{9}$/;

const ContactAccountPartyData = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('contact_account_party_data_modal');
    const {location, invoice, selectedSearchContact, initiateCommunicationFlow} = props;
    const searchQueryParams = new URLSearchParams(location?.search || '');
    const {confirmAccountPartyCreation, resetAccountPartyAddressCandidates} = props;
    const {confirmEditAccountParty, selectAccountPartyAddressGeolocation, searchAccountPartyAddressGeolocation} = props;
    const {fetchAccountParty, accountPartyDetails, addressCandidates, postCodeCandidates, cityCandidates} = props;
    const {addressErrorMessage, cityErrorMessage, postCodeErrorMessage} = props;

    const accountPartyType = searchQueryParams.get('accountPartyType');
    const modalType = searchQueryParams.get('type') || [];
    const isEditModeActive = !modalType.includes('CREATE');
    const isAccountPartyCreditor = accountPartyType === alfAccountTypes.CREDITOR;
    const sortedCountries = Object.entries(europeanCountries)
        .map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ])
        .sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            return countryA.name.localeCompare(countryB.name);
        });

    const [accountParty, setAccountParty] = useState((invoice?.accountParty
        ? invoice.accountParty
        : accountPartyDetails) || null);
    const selectedContact = isEditModeActive ? accountParty : selectedSearchContact;
    const [accountPartyFormData, setAccountPartyFormData] = useState({...selectedContact});
    const [country, setCountry] = useState(selectedContact?.address?.country || '');
    const [isVATNumberMandatory, setIsVATNumberMandatory] = useState(false);
    const [isIBANValid, setIsIBANValid] = useState(isEditModeActive && accountParty?.bankAccount?.iban
        ? isValidIBAN(electronicFormatIBAN(accountParty.bankAccount.iban.toUpperCase()))
        : true);
    const [isBICValid, setIsBICValid] = useState(isEditModeActive && accountParty?.bankAccount?.swiftCode
        ? swiftCodeRegex.test(accountParty.bankAccount.swiftCode)
        : true);
    const [isVATNoValid, setIsVATNoValid] = useState(false);
    const [isValidationCompleted, setIsValidationCompleted] = useState({
        iban: false,
        swiftCode: false,
    });

    const [addressQueryParamsMap, setAddressQueryParamsMap] = useState(new Map());
    const [hasStreetError, setHasStreetError] = useState(false);
    const [hasCityError, setHasCityError] = useState(false);
    const [hasPostCodeError, setHasPostCodeError] = useState(false);
    const [lastAddressSearchQuery, setLastAddressSearchQuery] = useState(null);
    const [newContactAddress, setNewContactAddress] = useState({
        street: selectedContact?.address?.street || '',
        postCode: selectedContact?.address?.postCode || '',
        city: selectedContact?.address?.city || '',
    });
    const client = searchQueryParams.get('client');

    const currentAddress = useRef('');

    const isCountryGermany = country === europeanCountries['DE'].name;
    const didComponentMountRef = useRef(true);

    const searchAddressGeolocationDebounced = useMemo(
        () => debounce(searchAccountPartyAddressGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchAccountPartyAddressGeolocation],
    );

    const validateVATNumber = useCallback(() => {
        if (!accountPartyFormData?.vatNumber && !isEditModeActive && isVATNumberMandatory) {
            setIsVATNoValid(false);
            return;
        }
        if (country === europeanCountries['DE'].name && accountPartyFormData?.vatNumber) {
            setIsVATNoValid(vatNumberRegex.test(accountPartyFormData.vatNumber));
            return;
        }
        setIsVATNoValid(true);
    }, [accountPartyFormData?.vatNumber, country, isEditModeActive, isVATNumberMandatory]);

    useEffect(() => {
        // From contact search screen
        if (didComponentMountRef.current && !invoice && isEditModeActive) {
            fetchAccountParty({
                accountPartyId: searchQueryParams.get('contactId'), // contactId is the same as accountPartyId
                accountPartyType,
                client,
            });
            didComponentMountRef.current = false;
        }
    });

    useEffect(() => {
        if (accountPartyDetails && isEditModeActive) {
            setAccountParty(accountPartyDetails);
            setAccountPartyFormData(accountPartyDetails);
            setCountry(accountPartyDetails.address?.country ? accountPartyDetails.address.country : '');
        }
    }, [accountPartyDetails, setAccountParty, setCountry, isEditModeActive]);

    useEffect(() => {
        if (isEditModeActive) return;
        // eslint-disable-next-line max-len
        const templateCodeFieldName = accountPartyType === alfAccountTypes.CREDITOR ? 'creditorTemplateCode' : 'debtorTemplateCode';
        setIsVATNumberMandatory(selectedContact?.['type'] === alfContactTypes.COMPANY
            && accountPartyFormData?.[templateCodeFieldName]
            && vatNumberDependencies[accountPartyType].includes(accountPartyFormData[templateCodeFieldName]));
    }, [selectedContact, isEditModeActive, accountPartyFormData, accountPartyType]);

    useEffect(() => {
        validateVATNumber();
    }, [validateVATNumber]);

    const handleOnValidationChange = field => {
        if (!isValidationCompleted[field]) return;
        setIsValidationCompleted({
            ...isValidationCompleted,
            [field]: false,
        });
    };

    const handleOnChange = formValues => {
        if (!formValues) return;

        setAccountPartyFormData({
            ...formValues,
            ...(!Object.keys(formValues.address).length && selectedContact
                ? {address: {
                    street: selectedContact.address?.street || '',
                    postCode: selectedContact.address?.postCode || '',
                    city: selectedContact.address?.city || '',
                    country: selectedContact.address?.country || '',
                }} : {address: {...formValues.address}}),
            ...(!Object.keys(formValues.bankAccount).length && isEditModeActive && accountParty
                ? {bankAccount: {
                    iban: accountParty.bankAccount?.iban || '',
                    swiftCode: accountParty.bankAccount?.swiftCode || '',
                    bank: accountParty.bankAccount?.name || '',
                }} : {bankAccount: {...formValues.bankAccount}}),
        });
    };

    const handleOnSubmit = () => {
        if (!accountPartyFormData) return;
        const templateCodeFieldName = isAccountPartyCreditor ? 'creditorTemplateCode' : 'debtorTemplateCode';

        const errors = ['city', 'postCode', 'street'].map(key => handleDatafactoryValidation(key));
        if (errors.some(error => !!error) || !!cityErrorMessage
            || !!postCodeErrorMessage || !!addressErrorMessage) return;

        const accountPartyData = {
            ...accountPartyFormData,
            id: selectedContact?.id,
            accountPartyType,
            creditorTemplateCode: isEditModeActive || templateCodeFieldName !== 'creditorTemplateCode' ? undefined : accountPartyFormData.creditorTemplateCode,
            debtorTemplateCode: isEditModeActive || templateCodeFieldName !== 'debtorTemplateCode' ? undefined : accountPartyFormData.debtorTemplateCode,
        };

        if (isEditModeActive) {
            confirmEditAccountParty({
                invoiceId: invoice?.id || null,
                client,
                accountPartyData,
            });
            return;
        }

        confirmAccountPartyCreation({
            invoiceId: invoice?.id || null,
            client,
            accountPartyData,
        });
    };

    const handleAddressSearchQueryChange = (searchQueryString, key) => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchAddressGeolocationDebounced({
                key,
                searchQueryString,
                addressQueryParamsMap,
            });
        }
        if (!searchQueryString) {
            setNewContactAddress({
                ...newContactAddress,
                [key]: '',
            });
            setAccountPartyFormData({
                ...accountPartyFormData,
                address: {
                    ...accountPartyFormData.address,
                    [key]: '',
                },
            });
            addressQueryParamsMap.delete(key);
            resetAccountPartyAddressCandidates({key});
        }

        setLastAddressSearchQuery({
            ...lastAddressSearchQuery,
            [key]: searchQueryString || '',
        });
    };

    const updateErrorStateByKey = (key, value) => {
        switch (key) {
            case 'city':
                setHasCityError(value);
                break;
            case 'postCode':
                setHasPostCodeError(value);
                break;
            case 'street':
                setHasStreetError(value);
                break;
        }
    };

    const validateAddress = (address, key) => {
        if (!address[key]) {
            updateErrorStateByKey(key, true);
            return true;
        }

        updateErrorStateByKey(key, false);
        return false;
    };

    const handleAddressCandidateSelect = (locationCandidate, key) => {
        currentAddress.current = locationCandidate[key];
        setLastAddressSearchQuery({
            ...lastAddressSearchQuery,
            [key]: locationCandidate[key],
        });

        setAddressQueryParamsMap(new Map(addressQueryParamsMap.set(
            key,
            key === 'street'
                ? locationCandidate[key].replace(/[^a-zA-Z]+/g, '')
                : locationCandidate[key],
        )));

        validateAddress(locationCandidate, key);

        setNewContactAddress({
            ...newContactAddress,
            [key]: locationCandidate[key],
        });

        setAccountPartyFormData({
            ...accountPartyFormData,
            address: {
                ...accountPartyFormData.address,
                [key]: locationCandidate[key],
            },
        });

        selectAccountPartyAddressGeolocation({
            key,
            uuid: locationCandidate.uuid,
            selectedFields: addressQueryParamsMap?.size ? Array.from(addressQueryParamsMap.keys()) : [],
        });
    };

    const handleOnAddressFocus = key => {
        resetAccountPartyAddressCandidates({key});
        if (addressQueryParamsMap?.size) {
            searchAddressGeolocationDebounced({
                key,
                addressQueryParamsMap,
            });
        }
    };

    const handleDatafactoryValidation = key => {
        if ((key === 'postCode' && !!postCodeErrorMessage)
            || (key === 'city' && !!cityErrorMessage)
            || (key === 'street' && !!addressErrorMessage)) {
            updateErrorStateByKey(key, true);
            return true;
        }

        if (!lastAddressSearchQuery || !Object.keys(lastAddressSearchQuery).includes(key)) return false;

        if (!lastAddressSearchQuery[key]) {
            updateErrorStateByKey(key, false);
            return false;
        }

        if (lastAddressSearchQuery[key] === newContactAddress[key]) {
            return validateAddress(accountPartyFormData.address, key);
        }

        updateErrorStateByKey(key, true);
        return true;
    };

    const isSaveCTADisabled = !accountPartyFormData?.name || !accountPartyFormData?.address?.street
        || !accountPartyFormData?.address?.postCode || !accountPartyFormData?.address?.city
        || !accountPartyFormData?.address?.country || (isVATNumberMandatory && !accountPartyFormData?.vatNumber)
        || (accountPartyFormData?.bankAccount?.iban && !isIBANValid)
        || (isAccountPartyCreditor && !accountPartyFormData?.bankAccount?.iban)
        || (!isEditModeActive && (isAccountPartyCreditor
            ? !accountPartyFormData?.creditorTemplateCode
            : !accountPartyFormData?.debtorTemplateCode))
        || (!!accountPartyFormData?.bankAccount?.swiftCode && !isBICValid)
        || (!!accountPartyFormData?.vatNumber && !isVATNoValid);

    return (
        <div
            className={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
                'global!ace-u-full-width',
            ])}
        >
            {accountPartyType && (
                <div className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-typography--variant-body-bold')}>
                    {translateModal('text.contact_data', {client: client || '', contactId: selectedContact?.id || ''})}
                </div>
            )}
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-full-width',
                ])}
            >
                <Form name="accountPartyForm" onChange={handleOnChange} onSubmit={handleOnSubmit}>
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-24')}>
                        <InputField
                            name="name"
                            label={`${translateModal('input_field_label.name')}*`}
                            className={cx('global!ace-u-grid-column--span-3')}
                            value={selectedContact?.name || ''}
                        />
                    </div>
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--24-0')}>
                        <Form name="address">
                            {!isCountryGermany && country ? (
                                <Fragment>
                                    <InputField
                                        name="city"
                                        label={`${translateModal('input_field_label.city')}*`}
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        value={accountPartyFormData?.address?.city || ''}
                                    />
                                    <InputField
                                        name="postCode"
                                        label={`${translateModal('input_field_label.post_code')}*`}
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        value={accountPartyFormData?.address?.postCode || ''}
                                    />
                                    <InputField
                                        name="street"
                                        label={`${translateModal('input_field_label.address')}*`}
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        value={accountPartyFormData?.address?.street || ''}
                                    />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <AutosuggestField
                                        name="city"
                                        label={`${translateModal('input_field_label.city')}*`}
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        value={accountPartyFormData?.address?.city || ''}
                                        optionValueSelector={addressCandidate => {
                                            return addressCandidate.city;
                                        }}
                                        onChange={value => handleAddressSearchQueryChange(value, 'city')}
                                        onOptionSelect={value => handleAddressCandidateSelect(value, 'city')}
                                        onFocus={() => handleOnAddressFocus('city')}
                                        errors={hasCityError ? cityErrorMessage
                                            ? [cityErrorMessage] : [translateModal('error_message.select_option_from_suggestions')]
                                            : []}
                                    >
                                        {cityCandidates?.slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                            .map((locationCandidate, index) => {
                                                return (
                                                    <Option
                                                        key={index}
                                                        name={`city-candidate-${index}`}
                                                        value={locationCandidate}
                                                    >
                                                        <Icon
                                                            icon={locationIcon}
                                                            className={cx('global!ace-u-margin--right-16')}
                                                        />
                                                        {locationCandidate.city}
                                                    </Option>
                                                );
                                            })
                                        }
                                    </AutosuggestField>
                                    <AutosuggestField
                                        name="postCode"
                                        label={`${translateModal('input_field_label.post_code')}*`}
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        value={accountPartyFormData?.address?.postCode || ''}
                                        optionValueSelector={addressCandidate => {
                                            return addressCandidate.postCode;
                                        }}
                                        onChange={value => handleAddressSearchQueryChange(value, 'postCode')}
                                        onOptionSelect={value => handleAddressCandidateSelect(value, 'postCode')}
                                        onFocus={() => handleOnAddressFocus('postCode')}
                                        errors={hasPostCodeError ? postCodeErrorMessage
                                            ? [postCodeErrorMessage] : [translateModal('error_message.select_option_from_suggestions')]
                                            : []}
                                    >
                                        {postCodeCandidates?.slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                            .map((locationCandidate, index) => {
                                                return (
                                                    <Option
                                                        key={index}
                                                        name={`post-code-candidate-${index}`}
                                                        value={locationCandidate}
                                                    >
                                                        <Icon
                                                            icon={locationIcon}
                                                            className={cx('global!ace-u-margin--right-16')}
                                                        />
                                                        {locationCandidate.postCode}
                                                    </Option>
                                                );
                                            })
                                        }
                                    </AutosuggestField>
                                    <AutosuggestField
                                        name="street"
                                        label={`${translateModal('input_field_label.address')}*`}
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        value={accountPartyFormData?.address?.street || ''}
                                        optionValueSelector={addressCandidate => {
                                            return addressCandidate.street;
                                        }}
                                        onChange={value => handleAddressSearchQueryChange(value, 'street')}
                                        onOptionSelect={value => handleAddressCandidateSelect(value, 'street')}
                                        onFocus={() => handleOnAddressFocus('street')}
                                        errors={hasStreetError ? addressErrorMessage
                                            ? [addressErrorMessage] : [translateModal('error_message.select_option_from_suggestions')]
                                            : []}
                                    >
                                        {addressCandidates?.slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                            .map((locationCandidate, index) => {
                                                return (
                                                    <Option
                                                        key={index}
                                                        name={`street-candidate-${index}`}
                                                        value={locationCandidate}
                                                    >
                                                        <Icon
                                                            icon={locationIcon}
                                                            className={cx('global!ace-u-margin--right-16')}
                                                        />
                                                        {locationCandidate.street}
                                                    </Option>
                                                );
                                            })
                                        }
                                    </AutosuggestField>
                                </Fragment>
                            )}
                            <AutocompleteField
                                name="country"
                                label={`${translateModal('select_field_label.country')}*`}
                                placeholder={translate('global.select.placeholder')}
                                icon={arrowDownIcon}
                                className={cx('global!ace-u-grid-column--span-3')}
                                value={country || ''}
                                onChange={setCountry}
                            >
                                {sortedCountries.length > 0 && sortedCountries
                                    .map(([countryCode, country]) => {
                                        return (
                                            <Option
                                                key={country.id}
                                                name={`country-${countryCode}`}
                                                value={europeanCountries[countryCode].name}
                                            >
                                                {country.name}
                                            </Option>
                                        );
                                    })}
                            </AutocompleteField>
                        </Form>
                    </div>
                    <Divider />
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--24-0')}>
                        {!isEditModeActive && (
                            <SelectField
                                name={isAccountPartyCreditor ? 'creditorTemplateCode' : 'debtorTemplateCode'}
                                label={isAccountPartyCreditor
                                    ? `${translateModal('select_field_label.creditor_template_code')}*`
                                    : `${translateModal('select_field_label.debtor_template_code')}*`
                                }
                                className={cx('global!ace-u-grid-column--span-3')}
                                value={(isAccountPartyCreditor
                                    ? accountPartyFormData?.creditorTemplateCode
                                    : accountPartyFormData?.debtorTemplateCode) || ''}
                            >
                                {selectedContact?.type
                                    && ((isAccountPartyCreditor
                                        ? client
                                            ? contactTypeToCreditorTemplateCodeMap[client][selectedContact.type]
                                            : []
                                        : client
                                            ? contactTypeToDebtorTemplateCodeMap[client][selectedContact.type]
                                            : []))
                                        .map((templateCode, idx) => (
                                            <Option
                                                key={`${templateCode}-${idx}`}
                                                name={templateCode}
                                                value={templateCode}
                                            >
                                                {translateModal(`select_option_label.${templateCode?.toLowerCase()}`)}
                                            </Option>
                                        ))}
                            </SelectField>
                        )}
                        <InputField
                            name="vatNumber"
                            label={isVATNumberMandatory ? `${translateModal('input_field_label.vat_number')}*` : translateModal('input_field_label.vat_number')}
                            className={cx('global!ace-u-grid-column--span-3')}
                            value={isEditModeActive && accountParty?.vatNumber ? accountParty?.vatNumber : ''}
                            errors={!isVATNoValid ? [translateModal('error_message.invalid_vat_no')] : []}
                        />
                    </div>
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--24-0')}>
                        <Form name="bankAccount">
                            <InputField
                                name="iban"
                                label={`${translateModal('input_field_label.iban')}${isAccountPartyCreditor ? '*' : ''}`}
                                className={cx('global!ace-u-grid-column--span-3')}
                                value={isEditModeActive && accountParty?.bankAccount ? accountParty.bankAccount.iban : ''}
                                onChange={() => handleOnValidationChange('iban')}
                                onBlur={() => {
                                    setIsIBANValid(isValidIBAN(electronicFormatIBAN(
                                        accountPartyFormData?.bankAccount?.iban?.toUpperCase(),
                                    )));
                                    setIsValidationCompleted({
                                        ...isValidationCompleted,
                                        iban: true,
                                    });
                                }}
                                errors={isValidationCompleted.iban && accountPartyFormData?.bankAccount?.iban && !isIBANValid ? [translateModal('error_message.invalid_iban')] : []}
                            />
                            <InputField
                                name="bankAccountName"
                                label={translateModal('input_field_label.bank')}
                                className={cx('global!ace-u-grid-column--span-3')}
                                value={isEditModeActive && accountParty?.bankAccount ? accountParty.bankAccount.name : ''}
                            />
                            <InputField
                                name="swiftCode"
                                label={translateModal('input_field_label.swiftCode')}
                                className={cx('global!ace-u-grid-column--span-3')}
                                value={isEditModeActive && accountParty?.bankAccount ? accountParty.bankAccount.swiftCode : ''}
                                onChange={() => handleOnValidationChange('swiftCode')}
                                onBlur={() => {
                                    accountPartyFormData?.bankAccount?.swiftCode
                                        ? setIsBICValid(swiftCodeRegex.test(
                                            accountPartyFormData.bankAccount.swiftCode,
                                        ))
                                        : setIsBICValid(true);
                                    setIsValidationCompleted({
                                        ...isValidationCompleted,
                                        swiftCode: true,
                                    });
                                }}
                                errors={isValidationCompleted.swiftCode && accountPartyFormData?.bankAccount?.swiftCode && !isBICValid ? [translateModal('error_message.invalid_bic')] : []}
                            />
                        </Form>
                    </div>
                    <Divider className={cx('global!ace-u-margin--24-0')} />
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                            'global!ace-u-margin--top-24',
                        ])}
                    >
                        <ButtonSecondary
                            name="communicationButton"
                            className={cx('global!ace-u-margin--right-24')}
                            onClick={initiateCommunicationFlow}
                            type="button"
                        >
                            <Icon
                                icon={communicationIcon}
                                className={cx([
                                    'global!ace-c-icon--color-highlight',
                                    'global!ace-u-margin--right-8',
                                ])}
                            />
                            {translateModal('button_label.communication')}
                        </ButtonSecondary>
                        <ButtonPrimary name="saveButton" type="submit" isDisabled={isSaveCTADisabled}>
                            <Icon
                                icon={saveIcon}
                                className={cx([
                                    'global!ace-c-icon--color-contrast',
                                    'global!ace-u-margin--right-8',
                                ])}
                            />
                            {translateModal('button_label.save')}
                        </ButtonPrimary>
                    </div>
                </Form>
            </div>
        </div>
    );
};

ContactAccountPartyData.propTypes = {
    location: PropTypes.object,
    invoice: PropTypes.object,
    accountPartyDetails: PropTypes.object,
    selectedSearchContact: PropTypes.object,
    confirmAccountPartyCreation: PropTypes.func.isRequired,
    confirmEditAccountParty: PropTypes.func.isRequired,
    fetchAccountParty: PropTypes.func.isRequired,
    initiateCommunicationFlow: PropTypes.func.isRequired,
    searchAccountPartyAddressGeolocation: PropTypes.func.isRequired,
    selectAccountPartyAddressGeolocation: PropTypes.func.isRequired,
    resetAccountPartyAddressCandidates: PropTypes.func.isRequired,
    addressCandidates: PropTypes.array,
    cityCandidates: PropTypes.array,
    postCodeCandidates: PropTypes.array,
    addressErrorMessage: PropTypes.string,
    cityErrorMessage: PropTypes.string,
    postCodeErrorMessage: PropTypes.string,
};

ContactAccountPartyData.defaultProps = {
    location: null,
    invoice: null,
    accountPartyDetails: null,
    selectedSearchContact: null,
    addressCandidates: [],
    cityCandidates: [],
    postCodeCandidates: [],
    addressErrorMessage: '',
    cityErrorMessage: '',
    postCodeErrorMessage: '',
};

const mapStateToProps = (state, props) => {
    const getSelectedContact = contactSelectors.createContactSelector();

    return {
        selectedSearchContact: getSelectedContact(state, props),
        accountPartyDetails: state.contacts.accountParty,
        addressCandidates: state.contacts.addressCandidates,
        cityCandidates: state.contacts.cityCandidates,
        postCodeCandidates: state.contacts.postCodeCandidates,
        addressErrorMessage: state.contacts.addressErrorMessage,
        cityErrorMessage: state.contacts.cityErrorMessage,
        postCodeErrorMessage: state.contacts.postCodeErrorMessage,
    };
};

const mapDispatchToProps = dispatch => ({
    confirmAccountPartyCreation: payload => dispatch({
        type: contactActionTypes.CONFIRM_CREATE_ACCOUNT_PARTY,
        payload,
    }),
    confirmEditAccountParty: payload => dispatch({
        type: contactActionTypes.CONFIRM_EDIT_ACCOUNT_PARTY,
        payload,
    }),
    fetchAccountParty: payload => dispatch({
        type: contactActionTypes.FETCH_ACCOUNT_PARTY,
        payload,
    }),
    initiateCommunicationFlow: payload => dispatch({
        type: contactActionTypes.INITIATE_CONTACT_COMMUNICATION_MODAL_FLOW,
        payload,
    }),
    searchAccountPartyAddressGeolocation: payload => dispatch({
        type: contactActionTypes.SEARCH_CONTACT_ADDRESS_GEOLOCATION,
        payload,
    }),
    selectAccountPartyAddressGeolocation: payload => dispatch({
        type: contactActionTypes.SELECT_CONTACT_ADDRESS_GEOLOCATION,
        payload,
    }),
    resetAccountPartyAddressCandidates: payload => dispatch({
        type: contactActionTypes.RESET_CONTACT_ADDRESS_CANDIDATES,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactAccountPartyData));
